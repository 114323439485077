
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './custom-palettes.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Theme colors
$primary-black: #161718;
$primary-light-gray: #F5F5F5;
$primary-white: #FFFFFF;
$secondary-light-purple: #ECF0FE;
$secondary-light-red: #FFF0F0;
$secondary-stroke-light-grey: #E4E6E7;
$accent-red: #FD1D1D;
$accent-accesible-red: #EB1414;
$accent-red-linear: linear-gradient(90deg, #FB6764 0%, #F7231E 100%);
$accent-red-purple-linear: linear-gradient(90deg, #FD4E4E 3.88%, #C779D0 48.29%, #6E8CF6 92.7%);
$background-light-gray: #ECECEC;
$typo-gray: #4F5158;

// Define the theme object.
$RA-theme: mat.define-theme((
  color: (
    primary: mat.$red-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($RA-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($ra-website-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($ra-website-theme);
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: "Lato", sans-serif; overflow-x: hidden;}

// Custom menu
.custom-menu {
  border-radius: 14px !important;
  box-shadow: 0px 4px 15px 0px #0000001A !important;
  max-width: unset !important;

  .mat-mdc-menu-content {
    padding: 0;
    display: flex;
    background-color: $primary-white;
    border: solid 1px $primary-light-gray !important;

    div {
      display: block;

      .menu-item {
        padding: 12px 20px;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        cursor: pointer;

        &:first-child {
          padding-top: 16px;
        }
        &:last-child {
          padding-bottom: 16px;
        }
      }

      .menu-item:hover {
        background-color: #F15F5F;
        color: $primary-white;
      }
    }
    
  }
}

// Custom tooltip
.full-width-tooltip {
  max-width: unset !important;
}

/**
** Custom material form fields style.
**
** We modify material wrappers to adapt the component style to our design
**/
.mat-mdc-form-field-error-wrapper {
  padding-left: 0 !important;
  font-family: "Lato", sans-serif !important;
}
.mdc-text-field--filled {
  background-color: transparent !important;
  // height: 48px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding-bottom: 0;
    padding-top: 20px;

    /* Input text color and font size customization */
    .mdc-text-field__input {
      font-family: "Lato", sans-serif;
      font-size: 18px;
      color: $primary-black;
    }
    .mdc-text-field__input::placeholder {
      color: #94969E;
    }
  }

  /* On hover, change the background color */
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  /* Change the border bottom color */
  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    border-bottom-color: $secondary-stroke-light-grey !important;
  }
  
  mat-label {
    color: #94969E;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    display: block;
    margin-top: 16px;
  }
}
  
.dark {
  .mdc-text-field--filled {
    .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
      /* Input text color */
      .mdc-text-field__input {
        color: $primary-light-gray;
      }
    }
  
    /* Change the border bottom color */
    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom-color: $primary-light-gray !important;
    }
  }
}

/**
**
**/
.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0;
  background-color: transparent !important;
  padding: 24px;
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px solid $secondary-stroke-light-grey;
  }

  .mat-expansion-panel-header {
    color: $primary-black !important;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    padding: 0 !important;
    height: 40px !important;

    &:hover {
      color: $accent-accesible-red !important;
    }

    &.mat-expanded {
      color: $accent-accesible-red !important;
      height: 40px !important;
    }

    &:hover {
      background-color: transparent !important;
    }
  }

  .mat-expansion-panel-body {
    font-family: "Lato", sans-serif;
    padding: 0 !important;
    margin-top: 7px;
    font-size: 14px;
  }
}

// Shared pages classes
.page-container {
  max-width: 1220px;
  width: 100%;

  h1, h2, h3, h4, p {
    margin: 0;
  }

  h1 {
    font-size: 105px;
    text-align: left;
    line-height: 112px;
    
  }
  h2 {
    margin-bottom: 24px;
    font-size: 86px;
  }
  h4 {
    font-size: 24px;
  }
  p {
    font-size: 18px;
    color: $typo-gray;
  }

  .header {
    h4 {
      margin-bottom: 12px;
      font-weight: 500;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;

    & > div {
      flex: 1;
      min-width: 300px;

      &:first-child {
        max-width: 525px;
      }
      &:last-child {
        max-width: 610px;
      }

      p:not(:last-child) {
        margin-bottom: 15px;
      }

      app-button {
        margin-top: 40px;
      }
    }
  }

  app-button {
    width: 160px;
    display: block;
  }
}